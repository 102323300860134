import React, { useEffect } from 'react';

const MapComponent = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//rf.revolvermaps.com/0/0/0.js?i=5dlhhpva2wh&d=3&p=0&b=0&w=293&g=2&f=arial&fs=12&r=0&c0=362b05&c1=375363&c2=666666&ic0=0&ic1=0";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {/* Разметка или контейнер для карты - Revolver Map */}
      <div className='revolver-map-container' id="revolver-map-container"></div>
    </>
  );
};

export default MapComponent;
