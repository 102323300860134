import React, { Component, useState, useEffect, useContext, useReducer, useMemo, useRef, lazy, Suspense } from 'react';
import { NavLink, Outlet, Routes, Route, useParams, useSearchParams, useLocation } from 'react-router-dom';
import SelectContext from './extension/SelectContext';


import { ToastContainer, toast } from 'react-toastify';
import { Audio } from 'react-loader-spinner';
import Loader from './extension/Loader_v.0.5';

const Celebrities = () => {
    const { storage } = useContext(SelectContext);

  const [text, setText] = useState({
      loading: `Loading Database...`,
      footbal:'Famous football players',
      basketball: 'Famous basketball players',
      violinist: 'Famous violinists',
      hockey: 'Famous hockey players',
      figure_skaters: 'Famous figure skaters',
      gymnasts: 'Famous gymnasts',
      pvam: "Famous Pole Vault Athletes (Men)",
      pvaw: "Famous Pole Vault Athletes (Women)",
      
  })

  useEffect(() => {
        console.log("Language changed:", storage.language);
        if (storage.language === "EN") {
            setText({
                loading: "Loading Database...",
                footbal:'Famous football players',
                basketball: 'Famous basketball players',
                violinist: 'Famous violinists',
                hockey: 'Famous hockey players',
                figure_skaters: 'Famous figure skaters',
                gymnasts: 'Famous gymnasts',
                pvam: "Famous Pole Vault Athletes (Men)",
                pvaw: "Famous Pole Vault Athletes (Women)",
                
            })
        } else if (storage.language === "RU") {
            setText({
                loading: "Загрузка базы данных...",
                footbal:'Знаменитые футболисты',
                basketball: 'Знаменитые баскетболисты',
                violinist: 'Знаменитые скрипачи',
                hockey: 'Знаменитые хоккеисты',
                figure_skaters: 'Знаменитые фигуристы',
                gymnasts: 'Знаменитые гимнасты',
                pvam: "Знаменитые легкоатлеты по прыжкам с шестом (мужчины)",
                pvaw: "Знаменитые легкоатлеты по прыжкам с шестом (женщины)",
                
            })
        } else {
            setText({
                loading: "Loading Database...",
                footbal:'Famous football players',
                basketball: 'Famous basketball players',
                violinist: 'Famous violinists',
                hockey: 'Famous hockey players',
                figure_skaters: 'Famous figure skaters',
                gymnasts: 'Famous gymnasts',
                pvam: "Famous Pole Vault Athletes (Men)",
                pvaw: "Famous Pole Vault Athletes (Women)",
                
            })
        };
  }, [storage.language]);


    
// -------------------- LOADER ---------------------

    // Загрузчик (Don't need it)
    
//   const loader = <div className='loader'>
//     <Audio className='loader' height="80" width="80" radius="9" color="inherit" ariaLabel="loading" />
//     <h2 style={{textAlign: "center"}}>{text.loading}</h2>
//   </div>;
    
  const loader = <Loader time={400}
    symbol={ <img style={{ margin: "10px" }} className='loader__img' width={"50px"} alt="." src={process.env.PUBLIC_URL + `/img/png/diamondBsmall.png`} />}
    total={4} size={60} reverse={false} />;

  return (
    <section className='block__celebrities'>
            <section className='result'>
                <nav id='list' className='result__nav'>
                    <ul>
                        <li className='result__nav__link'><NavLink className='mark' to="violinist">{text.violinist}</NavLink></li>
                        <li className='result__nav__link'><NavLink className='mark' to="football">{text.footbal}</NavLink></li>
                        <li className='result__nav__link'><NavLink className='mark' to="basketball">{text.basketball}</NavLink></li>
                        <li className='result__nav__link'><NavLink className='mark' to="hockey">{text.hockey}</NavLink></li>
                        <li className='result__nav__link'><NavLink className='mark' to="figure_skaters">{text.figure_skaters}</NavLink></li>
                        <li className='result__nav__link'><NavLink className='mark' to="gymnasts">{text.gymnasts}</NavLink></li>
                        <li className='result__nav__link'><NavLink className='mark' to="pole_vault_athletes_men">{text.pvam}</NavLink></li>
                        <li className='result__nav__link'><NavLink className='mark' to="pole_vault_athletes_women">{text.pvaw}</NavLink></li>
                    </ul>
                </nav>
            </section>
            {/* <section className='result__info'> */}
              <Outlet />
            {/* </section> */}
    </section>
  );
};

export default Celebrities;
